import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class CookiePage extends React.PureComponent<{}, {}> { 
    public render() {
        return (
            <LayoutTextPage title='Cookie voorwaarden'>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    <div>Hier komen de cookie vooraarden te staan</div>
                </div>   
        </LayoutTextPage>
        )}
}

export default CookiePage;
